var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c("el-col", { attrs: { span: 6 } }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "文案内容", prop: "content" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "54%" },
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "字体颜色", prop: "contentColor" } },
                [
                  _c("el-color-picker", {
                    attrs: { "show-alpha": "", predefine: _vm.predefineColors },
                    model: {
                      value: _vm.form.contentColor,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "contentColor", $$v)
                      },
                      expression: "form.contentColor"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.form.contentColor))])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "背景颜色", prop: "bgColor" } },
                [
                  _c("el-color-picker", {
                    attrs: { "show-alpha": "", predefine: _vm.predefineColors },
                    model: {
                      value: _vm.form.bgColor,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "bgColor", $$v)
                      },
                      expression: "form.bgColor"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.form.bgColor))])
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "预览" } }, [
                _c(
                  "p",
                  {
                    style:
                      "background-color: " +
                      _vm.form.bgColor +
                      ";color: " +
                      _vm.form.contentColor +
                      ";border-radius: 6px"
                  },
                  [_vm._v(_vm._s(_vm.form.content))]
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "起止日期" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "54%" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    on: {
                      change: function($event) {
                        return _vm.daterangeChange()
                      }
                    },
                    model: {
                      value: _vm.daterange,
                      callback: function($$v) {
                        _vm.daterange = $$v
                      },
                      expression: "daterange"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "medium" },
                      on: {
                        click: function($event) {
                          return _vm.subForm("form")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [_vm._v("返回")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-col", { attrs: { span: 6 } }, [_vm._v(" ")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }