"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _order = require("@/api/order");

var _integration = require("@/api/integration");

var _mockjs = _interopRequireDefault(require("mockjs"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      value: 8,
      details: {},
      dialogTableVisible: false,
      list: null,
      listLoading: true
    };
  },
  created: function created() {
    this.details = _mockjs.default.mock({
      id: '@id',
      name: '@name()',
      'status|1': ['待收货', '待整理', '申请退款', '待发货'],
      tradeNo: '@guid',
      'rebateAmount|1-100': 100,
      date: '@datetime',
      'price|1-1000': 1000,
      'quote|1-1000': 1000,
      'listing|3': [{
        id: '@id',
        name: '@name()',
        'count|1-10': 10,
        'price|1-100': 100
      }]
    });
    this.fetchData();
    this.getTradeRecord();
  },
  methods: {
    editOne: function editOne() {
      this.$router.push({
        path: '/basicInfo_edit',
        query: {
          type: 'add'
        }
      });
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _order.getList)().then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
      });
    },
    getOrderDetails: function getOrderDetails(id) {
      (0, _order.getDetails)(id).then(function (response) {
        console.log(response.data);
      });
    },
    viewOne: function viewOne(id) {
      this.dialogTableVisible = true; // this.getOrderDetails(id)
    },
    getTradeRecord: function getTradeRecord() {
      var _this2 = this;

      (0, _integration.getTradeList)().then(function (response) {
        _this2.gridData = response.data.items;
      });
    }
  }
};
exports.default = _default;