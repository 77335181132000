"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delOne = exports.addOne = exports.getList = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var getList = function getList(params) {
  return (0, _request.default)({
    url: '/message_template',
    method: 'get',
    params: params
  });
};

exports.getList = getList;

var addOne = function addOne(data) {
  return (0, _request.default)({
    url: "/message_template",
    method: 'post',
    data: data
  });
};

exports.addOne = addOne;

var delOne = function delOne(id) {
  return (0, _request.default)({
    url: "/message_template/".concat(id),
    method: 'delete'
  });
};

exports.delOne = delOne;