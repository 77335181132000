"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.editOne = editOne;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList() {
  return (0, _request.default)({
    url: '/setting',
    method: 'get'
  });
}

function editOne(data) {
  return (0, _request.default)({
    url: "/setting/".concat(data.id),
    method: 'put',
    data: data
  });
}