"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  china: function china(state) {
    return state.china.china;
  },
  menus: function menus(state) {
    return state.user.menus;
  },
  permission: function permission(state) {
    return state.user.permission;
  },
  rootCity: function rootCity(state) {
    return state.china.rootCity;
  }
};
var _default = getters;
exports.default = _default;