"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _integration = require("@/api/integration");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      dateRange: [],
      statistical: {
        amount: '',
        profit: '',
        rebate: '',
        total: ''
      },
      searchForm: {
        name: '',
        status: '',
        orderNo: '',
        merchantName: '',
        startDate: '',
        endDate: '',
        size: 15,
        page: 0
      },
      total: 0,
      pickerOptions: {
        disabledDate: function disabledDate(val) {
          var date1 = new Date(val);
          var date2 = new Date();

          if (date1.getTime() > date2.getTime()) {
            return true;
          } else {
            return false;
          }
        }
      },
      listLoading: true
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    reset: function reset() {
      this.dateRange = [];
      this.searchForm = {
        name: '',
        status: '',
        orderNo: '',
        merchantName: '',
        startDate: '',
        endDate: '',
        size: 15,
        page: 0
      };
      this.fetchData();
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(e) {
      this.searchForm.page = e - 1;
      this.fetchData();
    },
    dateChange: function dateChange() {
      this.searchForm.startDate = new Date(this.dateRange[0]).getTime();
      var date = new Date(this.dateRange[1]);
      date.setDate(date.getDate() + 1);
      date.setSeconds(date.getSeconds() - 1);
      this.searchForm.endDate = date.getTime();
    },
    search: function search() {
      this.searchForm.page = 0;
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      (0, _integration.getList)(this.searchForm).then(function (response) {
        _this.list = response.data.content;
        _this.total = response.data.totalElements;
        _this.statistical = response.data.statistical;
        _this.listLoading = false;
      });
    }
  }
};
exports.default = _default;