"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getInfo = getInfo;
exports.logout = logout;
exports.getCode = exports.resetPassword = exports.resetPhone = exports.getPermission = exports.deleteOne = exports.getOne = exports.editOne = exports.addOne = exports.getList = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var _jsMd = _interopRequireDefault(require("js-md5"));

function login(data) {
  data.password = (0, _jsMd.default)(data.password);
  return (0, _request.default)({
    url: '/user/login',
    method: 'post',
    data: data
  });
}

function getInfo() {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get'
  });
}

function logout() {
  return (0, _request.default)({
    url: '/admin/user/logout',
    method: 'post'
  });
}

var getList = function getList(params) {
  return (0, _request.default)({
    url: '/users',
    method: 'get',
    params: params
  });
};

exports.getList = getList;

var addOne = function addOne(data) {
  return (0, _request.default)({
    url: "/users",
    method: 'post',
    data: data
  });
};

exports.addOne = addOne;

var editOne = function editOne(data) {
  console.log(data);
  return (0, _request.default)({
    url: "/users/".concat(data.id),
    method: 'put',
    data: data
  });
};

exports.editOne = editOne;

var getOne = function getOne(id) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: 'get'
  });
};

exports.getOne = getOne;

var deleteOne = function deleteOne(id) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: 'delete'
  });
};

exports.deleteOne = deleteOne;

var getPermission = function getPermission() {
  return (0, _request.default)({
    url: "/users/permission",
    method: 'get'
  });
};

exports.getPermission = getPermission;

var resetPhone = function resetPhone(temp) {
  var data = temp;
  temp.password = (0, _jsMd.default)(temp.password);
  return (0, _request.default)({
    url: "/users/reset/phone",
    method: 'put',
    data: data
  });
};

exports.resetPhone = resetPhone;

var resetPassword = function resetPassword(temp) {
  var data = temp;
  temp.password = (0, _jsMd.default)(temp.password);
  return (0, _request.default)({
    url: "/users/reset/password",
    method: 'put',
    data: data
  });
};

exports.resetPassword = resetPassword;

var getCode = function getCode(params) {
  return (0, _request.default)({
    url: "/code/".concat(params.phone),
    method: 'get'
  });
};

exports.getCode = getCode;