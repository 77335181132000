"use strict";

var _interopRequireWildcard = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/core-js/modules/es6.array.iterator.js");

require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/core-js/modules/es6.promise.js");

require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/core-js/modules/es6.object.assign.js");

require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/icons");

require("@/permission");

var _jsMd = _interopRequireDefault(require("js-md5"));

var _moment = _interopRequireDefault(require("moment"));

var filters = _interopRequireWildcard(require("./filter"));

// A modern alternative to CSS resets
// lang i18n
// global css
// icon
// permission control
_vue.default.prototype.$moment = _moment.default;
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// 公共filter

_vue.default.prototype.$md5 = _jsMd.default;
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
}); // set ElementUI lang to EN

_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});