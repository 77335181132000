"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

var _user = require("@/api/user");

var _auth = require("@/utils/auth");

var _router = require("@/router");

var _index = require("@/router/index");

var _router2 = require("../../router");

var state = {
  token: (0, _auth.getToken)(),
  name: '',
  avatar: '',
  permission: '',
  menus: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_PERMISSION: function SET_PERMISSION(state, permission) {
    state.permission = permission;
  },
  SET_MENUS: function SET_MENUS(state, menus) {
    state.menus = menus;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        username: username.trim(),
        password: password
      }).then(function (response) {
        console.log(response);
        var data = response.data;
        commit('SET_TOKEN', data.access_token);
        (0, _auth.setToken)(data.access_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(state.token).then(function (response) {
        var data = response.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        var name = data.name,
            avatar = data.avatar,
            permissions = data.permissions;
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        commit('SET_PERMISSION', permissions);
        var menus = [];

        if (permissions) {
          var permissionArray = permissions.toString().split(",");
          permissionArray.sort().forEach(function (item) {
            if (_index.map[item]) menus.push(_index.map[item]);
          });
        }

        (0, _router2.addRouter)(permissions);
        commit('SET_MENUS', menus);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', '');
      (0, _auth.removeToken)();
      (0, _router.resetRouter)();
      resolve();
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      (0, _auth.removeToken)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;