var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: "5" } }, [_vm._v(" ")]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: "14" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "员工名字" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录帐号" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.account,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "account", $$v)
                          },
                          expression: "form.account"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.type === "add"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "密码" } },
                        [
                          _c("el-input", {
                            attrs: { type: "password" },
                            model: {
                              value: _vm.form.password,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单权限" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.permissionsChane },
                          model: {
                            value: _vm.checkList,
                            callback: function($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList"
                          }
                        },
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "0", value: "1" } },
                            [_vm._v("会员管理")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "1", value: "2" } },
                            [_vm._v("商家管理")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "2", value: "3" } },
                            [_vm._v("交易管理")]
                          ),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "3" } }, [
                            _vm._v("轮播图管理")
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "4" } }, [
                            _vm._v("用户反馈")
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "5" } }, [
                            _vm._v("平台操作日志")
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "6" } }, [
                            _vm._v("客服管理")
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "7" } }, [
                            _vm._v("管理员权限管理")
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "8" } }, [
                            _vm._v("系统安全")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "机密权限" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.permissionsChane },
                          model: {
                            value: _vm.checkList,
                            callback: function($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList"
                          }
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "10" } }, [
                            _vm._v("会员返利操作")
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "11" } }, [
                            _vm._v("商家抽成设置")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { label: "" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "15ch" },
                          attrs: { type: "primary", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("form")
                            }
                          }
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "15ch" },
                          attrs: { size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.$router.back()
                            }
                          }
                        },
                        [_vm._v("返回")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-col", { attrs: { span: "5" } }, [_vm._v(" ")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }