var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c("el-col", { attrs: { span: "6" } }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "el-col",
        { attrs: { span: "12" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司名称" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _vm._v("\n           \n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "抽成比例" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: !_vm.hasPermission("11") },
                            model: {
                              value: _vm.form.commission,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "commission", $$v)
                              },
                              expression: "form.commission"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "服务范围" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      size: "medium",
                      options: _vm.rootCity.children,
                      props: _vm.props,
                      itemscope: "",
                      "change-on-select": "false",
                      placeholder: "选择地区"
                    },
                    on: { change: _vm.cityChange },
                    model: {
                      value: _vm.form.areaId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "areaId", $$v)
                      },
                      expression: "form.areaId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "法人姓名" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.legalPersonName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "legalPersonName", $$v)
                              },
                              expression: "form.legalPersonName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _vm._v("\n           \n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "法人手机" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "办公场地" } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: _vm.upload,
                                "file-list": _vm.fileList,
                                "on-success": _vm.handleImage1Success,
                                "list-type": "picture-card",
                                "auto-upload": true
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "file",
                                  fn: function(ref) {
                                    var file = ref.file
                                    return _c("div", {}, [
                                      _c("img", {
                                        staticClass:
                                          "el-upload-list__item-thumbnail",
                                        attrs: { src: file.url, alt: "" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-actions"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-preview",
                                              on: {
                                                click: function($event) {
                                                  return _vm.handlePictureCardPreview(
                                                    file
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-zoom-in"
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.disabled
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-upload-list__item-delete",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleRemove(
                                                        file
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  }
                                }
                              ])
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "default" },
                                slot: "default"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dialog",
                            {
                              attrs: { visible: _vm.dialogVisibleShow },
                              on: {
                                "update:visible": function($event) {
                                  _vm.dialogVisibleShow = $event
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.dialogImageUrl,
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _vm._v("\n           \n        ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.upload,
                        "show-file-list": false,
                        "on-success": _vm.handleImage2Success,
                        "before-upload": _vm.beforeAvatarUpload
                      }
                    },
                    [
                      _vm.form.businessLicense
                        ? _c("img", {
                            staticClass:
                              "avatar el-upload-list__item-thumbnail",
                            attrs: { src: _vm.form.businessLicense }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                            staticStyle: {
                              border: "1px dashed #d9d9d9",
                              "border-radius": "6px",
                              cursor: "pointer",
                              position: "relative",
                              overflow: "hidden"
                            }
                          })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "margin-top": "-130px" } }, [
                    _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-preview",
                            on: {
                              click: function($event) {
                                return _vm.handlePictureCardPreview({
                                  url: _vm.form.businessLicense
                                })
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-zoom-in" })]
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "头像" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.upload,
                        "show-file-list": false,
                        "on-success": _vm.handleImage3Success,
                        "before-upload": _vm.beforeAvatarUpload
                      }
                    },
                    [
                      _vm.form.avatar
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.form.avatar }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                            staticStyle: {
                              border: "1px dashed #d9d9d9",
                              "border-radius": "6px",
                              cursor: "pointer",
                              position: "relative",
                              overflow: "hidden"
                            }
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "微信账号" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.wechatphone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "wechatphone", $$v)
                      },
                      expression: "form.wechatphone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "支付宝账号" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.aliphone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "aliphone", $$v)
                      },
                      expression: "form.aliphone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公司地址" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公司介绍" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 4 },
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.form.intro,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "intro", $$v)
                      },
                      expression: "form.intro"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "主营业务" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 4 },
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.form.business,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "business", $$v)
                      },
                      expression: "form.business"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "15ch" },
                      attrs: { type: "primary", size: "medium" },
                      on: {
                        click: function($event) {
                          return _vm.subForm("form")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "15ch" },
                      attrs: { size: "medium" },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [_vm._v("返回")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-col", { attrs: { span: "6" } }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "新增区域", visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c("el-form-item", { attrs: { label: "服务范围" } }, [
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("span", { staticClass: "demonstration" }, [
                      _vm._v("默认显示所有Tag")
                    ]),
                    _vm._v(" "),
                    _c("el-cascader", {
                      attrs: {
                        options: _vm.rootCity.children,
                        props: _vm.props,
                        clearable: ""
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("span", { staticClass: "demonstration" }, [
                      _vm._v("折叠展示Tag")
                    ]),
                    _vm._v(" "),
                    _c("el-cascader", {
                      attrs: {
                        options: _vm.rootCity.children,
                        props: _vm.props,
                        "collapse-tags": "",
                        clearable: ""
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }