var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "query-wrapper" }, [
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-form",
              { staticClass: "demo-form-inline", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "订单号" } },
                  [_c("el-input", { attrs: { placeholder: "订单号" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: { type: "date", placeholder: "选择日期" },
                      model: {
                        value: _vm.value1,
                        callback: function($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-button",
              {
                staticClass: "query-button",
                attrs: { type: "primary", plain: "" }
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c("el-button", [_vm._v("重置")])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            "cell-style": { padding: "0.5ch" },
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "会员名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单编号", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.tradeNo) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.status))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单金额", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.price) + "￥\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "返利金额",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.rebateAmount) +
                        "￥\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "交易时间",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.date))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "操作",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.viewOne(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "pagination-app" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": 100,
              layout: "total, prev, pager, next",
              total: 1000
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "订单详情", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("H4", [_vm._v("订单编号：" + _vm._s(_vm.details.tradeNo))])
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 4 } }, [_vm._v("    ")]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("H4", [
                    _vm._v("订单报价：" + _vm._s(_vm.details.price) + "元")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("H2", [_vm._v("订单清单")]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.details.listing,
                "max-height": "600",
                label: "订单清单"
              }
            },
            [
              _c("el-table-column", {
                attrs: { property: "name", label: "物价名称" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "count", label: "数量" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "price", label: "单价" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [_c("H4", [_vm._v("总计金额：10000 元")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [_c("H4", [_vm._v("商家名称：test")])],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [_c("H4", [_vm._v("收货人：test")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [_c("H4", [_vm._v("邮政编码：132000")])],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [_c("H4", [_vm._v("联系方式：18161213298")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [_c("H4", [_vm._v("快递公司：圆通")])],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [_c("H4", [_vm._v("快递单号：1111111111111")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("H4", [
                    _vm._v("收货地址：四川省成都市武侯区吉瑞三路环球时代中心")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [_c("H4", [_vm._v("评分：")])],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 22 } },
                [
                  _c("el-rate", {
                    staticStyle: { margin: "18px" },
                    attrs: {
                      disabled: "",
                      max: 10,
                      "show-score": "",
                      "text-color": "#ff9900",
                      "score-template": "{value}"
                    },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogTableVisible = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }