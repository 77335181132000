"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCity = exports.aduit = exports.enabled = exports.disabled = exports.getOne = exports.editOne = exports.addOne = exports.getList = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var _jsMd = _interopRequireDefault(require("js-md5"));

var getList = function getList(params) {
  return (0, _request.default)({
    url: '/members',
    method: 'get',
    params: params
  });
};

exports.getList = getList;

var addOne = function addOne(data) {
  data.password = (0, _jsMd.default)(data.password);
  return (0, _request.default)({
    url: "/members",
    method: 'post',
    data: data
  });
};

exports.addOne = addOne;

var editOne = function editOne(data) {
  return (0, _request.default)({
    url: "/members/".concat(data.id),
    method: 'put',
    data: data
  });
};

exports.editOne = editOne;

var getOne = function getOne(id) {
  return (0, _request.default)({
    url: "/members/".concat(id),
    method: 'get'
  });
};

exports.getOne = getOne;

var disabled = function disabled(id) {
  return (0, _request.default)({
    url: "/members/".concat(id, "/disabled"),
    method: 'put'
  });
};

exports.disabled = disabled;

var enabled = function enabled(id) {
  return (0, _request.default)({
    url: "/members/".concat(id, "/enabled"),
    method: 'put'
  });
};

exports.enabled = enabled;

var aduit = function aduit(data) {
  return (0, _request.default)({
    url: '/members/audit',
    method: 'post',
    data: data
  });
};

exports.aduit = aduit;

var getCity = function getCity() {
  return (0, _request.default)({
    url: '/city',
    method: 'get'
  });
};

exports.getCity = getCity;