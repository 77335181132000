var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: "8" } }, [_vm._v(" ")]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: "8" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: "18" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "新手机号码", prop: "phone" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "phone", $$v)
                                  },
                                  expression: "form.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: "5" } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.show,
                                  expression: "show"
                                }
                              ],
                              staticStyle: { "margin-left": "5px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.getCode }
                            },
                            [_vm._v("获取短信验证码")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.show,
                                  expression: "!show"
                                }
                              ],
                              staticStyle: { "margin-left": "5px" },
                              attrs: { type: "primary", disabled: "true" },
                              on: { click: _vm.getCode }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.count) +
                                  "s\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "短信验证码", prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.code,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "输入登录密码", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password" },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "15ch" },
                          attrs: { type: "primary", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.subForm("form")
                            }
                          }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-col", { attrs: { span: "8" } }, [_vm._v(" ")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }