"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.addRouter = addRouter;
exports.default = exports.constantRoutes = exports.map = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.split");

var _interopRequireWildcard2 = _interopRequireDefault(require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _user = require("@/api/user");

_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var map = [{
  path: '/',
  component: _layout.default,
  redirect: '/basicInfo',
  name: 'basicInfo',
  meta: {
    title: '会员管理',
    icon: 'example'
  },
  children: [{
    path: 'basicInfo',
    name: 'basicInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/basicInfo'));
      });
    },
    meta: {
      title: '会员基本信息',
      icon: 'user'
    }
  }, {
    path: 'basicInfo_edit',
    name: 'basicInfo_edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/basicInfoEdit'));
      });
    },
    meta: {
      title: '会员基本信息编辑',
      icon: 'dashboard'
    },
    hidden: true
  }, {
    path: 'tradeRecord',
    name: 'tradeRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/tradeRecord'));
      });
    },
    meta: {
      title: '会员充值记录',
      icon: 'form'
    }
  }, {
    path: 'order_record',
    name: 'order_record',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/orderRecord'));
      });
    },
    meta: {
      title: '订单记录',
      icon: 'dashboard'
    },
    hidden: true
  }]
}, {
  path: '/merchants',
  component: _layout.default,
  redirect: '/merchants/basicInfo/listing',
  name: 'merchants',
  meta: {
    title: '商家管理',
    icon: 'example'
  },
  children: [{
    path: 'basicInfo',
    name: 'basicInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/merchants/basicInfo/index'));
      });
    },
    children: [{
      path: 'listing',
      name: 'listing',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/merchants/basicInfo/listing'));
        });
      },
      meta: {
        title: '商家基本信息',
        icon: 'shop'
      }
    }, {
      path: 'edit',
      name: 'edit',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/merchants/basicInfo/edit'));
        });
      },
      meta: {
        title: '基本信息',
        icon: 'form'
      },
      hidden: true
    }]
  }, {
    path: 'withdrawDeposit',
    name: 'withdrawDeposit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/merchants/withdrawDeposit/index'));
      });
    },
    meta: {
      title: '商家提现记录',
      icon: 'money'
    }
  }]
}, {
  path: '/trade',
  component: _layout.default,
  redirect: '/trade/order',
  name: 'trade',
  meta: {
    title: '交易管理',
    icon: 'example'
  },
  children: [{
    path: 'order',
    name: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/trade/order/index'));
      });
    },
    meta: {
      title: '订单记录',
      icon: 'form'
    }
  }, {
    path: 'integral',
    name: 'integral',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/trade/integral/index'));
      });
    },
    meta: {
      title: '积分记录',
      icon: 'form'
    }
  }, {
    path: 'rebate',
    name: 'rebate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/trade/rebate/index'));
      });
    },
    meta: {
      title: '返利积分',
      icon: 'form'
    }
  }]
}, {
  path: '/carousel',
  component: _layout.default,
  children: [{
    path: 'index',
    name: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/carousel/index'));
      });
    },
    meta: {
      title: '轮播图管理',
      icon: 'picture'
    }
  }, {
    path: 'edit',
    name: 'edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/carousel/edit'));
      });
    },
    meta: {
      title: '轮播图编辑',
      icon: 'form'
    },
    hidden: true
  }]
}, {
  path: '/marquee',
  component: _layout.default,
  children: [{
    path: 'index',
    name: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marquee/index'));
      });
    },
    meta: {
      title: '跑马灯管理',
      icon: 'picture'
    }
  }, {
    path: 'edit',
    name: 'edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marquee/edit'));
      });
    },
    meta: {
      title: '跑马灯编辑',
      icon: 'form'
    },
    hidden: true
  }]
}, {
  path: '/feedback',
  component: _layout.default,
  children: [{
    path: 'index',
    name: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/feedback/index'));
      });
    },
    meta: {
      title: '用户反馈',
      icon: 'picture'
    }
  }]
}, {
  path: '/services',
  component: _layout.default,
  children: [{
    path: 'index',
    name: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/qq/index'));
      });
    },
    meta: {
      title: '客服管理',
      icon: 'picture'
    }
  }]
}, {
  path: '/log',
  component: _layout.default,
  children: [{
    path: 'index',
    name: 'Log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/log/index'));
      });
    },
    meta: {
      title: '平台操作日志',
      icon: 'log'
    }
  }]
}, {
  path: '/admin',
  component: _layout.default,
  children: [{
    path: 'index',
    name: 'Admin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/index'));
      });
    },
    meta: {
      title: '管理员权限管理',
      icon: 'security'
    }
  }, {
    path: 'edit',
    name: 'AdminEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/edit'));
      });
    },
    meta: {
      title: '管理员编辑',
      icon: 'form'
    },
    hidden: true
  }]
}, {
  path: '/system',
  component: _layout.default,
  name: 'System',
  redirect: '/system/password',
  meta: {
    title: '系统安全',
    icon: 'example'
  },
  children: [{
    path: 'password',
    name: 'password',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/password/index'));
      });
    },
    meta: {
      title: '修改密码',
      icon: 'password'
    }
  }, {
    path: 'phone',
    name: 'Phone',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/phone/index'));
      });
    },
    meta: {
      title: '更换手机号',
      icon: 'mobile'
    }
  }, {
    path: 'message',
    name: 'Message',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/message/index'));
      });
    },
    meta: {
      title: '系统消息推送',
      icon: 'message'
    }
  }]
}];
exports.map = map;
var constantRoutes = [{
  path: '/',
  redirect: '/basicInfo',
  hidden: true
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.constantRoutes = constantRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

function addRouter(permissions) {
  var menus = new Array();

  if (permissions) {
    var permissionArray = permissions.toString().split(",");
    permissionArray.forEach(function (item, index) {
      if (map[item]) menus.push(map[item]);
    });
  }

  router.addRoutes(menus);
}

var _default = router;
exports.default = _default;