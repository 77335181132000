"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCity = exports.areaChange = exports.aduitOk = exports.aduitNo = exports.enabled = exports.disabled = exports.getOne = exports.editOne = exports.addOne = exports.getList = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var getList = function getList(params) {
  return (0, _request.default)({
    url: '/merchants',
    method: 'get',
    params: params
  });
};

exports.getList = getList;

var addOne = function addOne(data) {
  return (0, _request.default)({
    url: "/merchants",
    method: 'post',
    data: data
  });
};

exports.addOne = addOne;

var editOne = function editOne(data) {
  return (0, _request.default)({
    url: "/merchants/".concat(data.id),
    method: 'put',
    data: data
  });
};

exports.editOne = editOne;

var getOne = function getOne(id) {
  return (0, _request.default)({
    url: "/merchants/".concat(id),
    method: 'get'
  });
};

exports.getOne = getOne;

var disabled = function disabled(id) {
  return (0, _request.default)({
    url: "/merchants/".concat(id, "/disabled"),
    method: 'put'
  });
};

exports.disabled = disabled;

var enabled = function enabled(id) {
  return (0, _request.default)({
    url: "/merchants/".concat(id, "/enabled"),
    method: 'put'
  });
};

exports.enabled = enabled;

var aduitNo = function aduitNo(data) {
  return (0, _request.default)({
    url: "/merchants/".concat(data.id, "/audit_no"),
    method: 'put',
    data: data
  });
};

exports.aduitNo = aduitNo;

var aduitOk = function aduitOk(id, params) {
  return (0, _request.default)({
    url: "/merchants/".concat(id, "/audit_ok"),
    method: 'put',
    params: params
  });
};

exports.aduitOk = aduitOk;

var areaChange = function areaChange(params) {
  return (0, _request.default)({
    url: "/merchants/".concat(params.id, "/area"),
    method: 'put',
    params: params
  });
};

exports.areaChange = areaChange;

var getCity = function getCity() {
  return (0, _request.default)({
    url: '/city',
    method: 'get'
  });
};

exports.getCity = getCity;