"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(params) {
  return (0, _request.default)({
    url: '/recharge',
    method: 'get',
    params: params
  });
}