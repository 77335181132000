var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "query-wrapper" }, [
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-form",
              { staticClass: "demo-form-inline", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "客服名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "客服名称" },
                      model: {
                        value: _vm.searchForm.serviceName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "serviceName", $$v)
                        },
                        expression: "searchForm.serviceName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "QQ" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "qq" },
                      model: {
                        value: _vm.searchForm.qq,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "qq", $$v)
                        },
                        expression: "searchForm.qq"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-button",
              {
                staticClass: "query-button",
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.search }
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重置")])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bread-nav-wrapper" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "el-button",
              {
                staticClass: "query-button",
                attrs: {
                  type: "primary",
                  plain: "",
                  size: "min",
                  icon: "el-icon-plus"
                },
                on: {
                  click: function($event) {
                    return _vm.addOne()
                  }
                }
              },
              [_vm._v("\n        新增\n      ")]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "tips", attrs: { "data-v-17fbe54e": "" } },
              [
                _vm._v(
                  "注意：成功添加的客服QQ，需登录QQ推广官网才能正常使用（仅登录即可，无须其他操作）"
                ),
                _c(
                  "a",
                  {
                    staticClass: "shang-qq",
                    on: {
                      click: function($event) {
                        return _vm.loginQQ()
                      }
                    }
                  },
                  [_vm._v("点击登录")]
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            "cell-style": { padding: "0.5ch" },
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "员工名字" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "客服名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.serviceName) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "QQ号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(scope.row.qq))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "操作",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.viewOne(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.deleteOne(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "pagination-app" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": _vm.searchForm.size,
              layout: "total, prev, pager, next",
              total: _vm.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "详情",
            visible: _vm.dialogTableVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "150px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "员工名字" } },
                [
                  _c("el-input", {
                    attrs: { readonly: _vm.readOnly },
                    model: {
                      value: _vm.details.name,
                      callback: function($$v) {
                        _vm.$set(_vm.details, "name", $$v)
                      },
                      expression: "details.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "客服名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: _vm.readOnly },
                    model: {
                      value: _vm.details.serviceName,
                      callback: function($$v) {
                        _vm.$set(_vm.details, "serviceName", $$v)
                      },
                      expression: "details.serviceName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "qq" } },
                [
                  _c("el-input", {
                    attrs: { readonly: _vm.readOnly },
                    model: {
                      value: _vm.details.qq,
                      callback: function($$v) {
                        _vm.$set(_vm.details, "qq", $$v)
                      },
                      expression: "details.qq"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  !_vm.readOnly
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.send()
                            }
                          }
                        },
                        [_vm._v("发送")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.readOnly
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v("取消")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.readOnly
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v("关闭")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }