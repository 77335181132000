"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _log = require("@/api/log");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      dateRange: [],
      searchForm: {
        account: '',
        startDate: '',
        endDate: '',
        size: 15,
        page: 0
      },
      details: {},
      list: null,
      listLoading: false
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    reset: function reset() {
      this.dateRange = [];
      this.searchForm = {
        account: '',
        startDate: '',
        endDate: '',
        size: 15,
        page: 0
      };
      this.fetchData();
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(e) {
      this.searchForm.page = e - 1;
      this.fetchData();
    },
    editOne: function editOne() {
      this.$router.push({
        path: '/carousel/edit',
        query: {
          type: 'add'
        }
      });
    },
    search: function search() {
      this.searchForm.page = 0;
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      console.log(this.searchForm);
      (0, _log.getList)(this.searchForm).then(function (response) {
        _this.list = response.data.content;
        _this.total = response.data.totalElements;
        ;
        _this.listLoading = false;
      });
    },
    dateChange: function dateChange() {
      this.searchForm.startDate = new Date(this.dateRange[0]).getTime();
      var date = new Date(this.dateRange[1]);
      date.setDate(date.getDate() + 1);
      date.setSeconds(date.getSeconds() - 1);
      this.searchForm.endDate = date.getTime();
    }
  }
};
exports.default = _default;