"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteOne = exports.getOne = exports.editOne = exports.addOne = exports.getList = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var getList = function getList(params) {
  return (0, _request.default)({
    url: '/horseLamps',
    method: 'get',
    params: params
  });
};

exports.getList = getList;

var addOne = function addOne(data) {
  return (0, _request.default)({
    url: "/horseLamps",
    method: 'post',
    data: data
  });
};

exports.addOne = addOne;

var editOne = function editOne(data) {
  return (0, _request.default)({
    url: "/horseLamps/".concat(data.id),
    method: 'put',
    data: data
  });
};

exports.editOne = editOne;

var getOne = function getOne(id) {
  return (0, _request.default)({
    url: "/horseLamps/".concat(id),
    method: 'get'
  });
};

exports.getOne = getOne;

var deleteOne = function deleteOne(id) {
  return (0, _request.default)({
    url: "/horseLamps/".concat(id),
    method: 'delete'
  });
};

exports.deleteOne = deleteOne;