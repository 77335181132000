var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "query-wrapper" }, [
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-form",
              {
                staticClass: "demo-form-inline",
                attrs: { inline: true, "label-position": "right" }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "商家名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "商家名称" },
                      model: {
                        value: _vm.searchForm.name,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "name", $$v)
                        },
                        expression: "searchForm.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.fetchData },
                        model: {
                          value: _vm.searchForm.status,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "status", $$v)
                          },
                          expression: "searchForm.status"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "正常", value: "1" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "封禁", value: "2" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "待审核", value: "0" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-button",
              {
                staticClass: "query-button",
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.fetchData }
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重置")])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            "cell-style": { padding: "0.5ch" },
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "商家名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "联系电话" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.phone) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "法人姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.legalPersonName) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "抽成比例" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("commission")(scope.row.commission)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "积分" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.integration) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "状态",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("merchantStatus")(scope.row)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "操作",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.editOne(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    scope.row.status === 0 || scope.row.status === 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.auditOne(scope.row)
                              }
                            }
                          },
                          [_vm._v("审核\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.enableOne(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("解禁")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.disableOne(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("封禁")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.applicationChangeArea
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.changeArea(scope.row)
                              }
                            }
                          },
                          [_vm._v("更改区域")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "pagination-app" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": _vm.searchForm.size,
              layout: "total, prev, pager, next",
              total: _vm.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "20%", width: "70%" },
          attrs: { title: "更改区域", visible: _vm.changeRegion },
          on: {
            "update:visible": function($event) {
              _vm.changeRegion = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "right" } },
            [
              _c("el-form-item", { attrs: { label: "公司名称:" } }, [
                _vm._v("\n        " + _vm._s(_vm.details.name) + "\n      ")
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "申请更改服务区域为:", width: "11" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("applicationCityName")(_vm.details)) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "nav",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.auditRegion(1)
                        }
                      }
                    },
                    [_vm._v("审核通过")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.auditRegion(2)
                        }
                      }
                    },
                    [_vm._v("审核不通过")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "right" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "公司名称:" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.auditDetails.name) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "抽成比例:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "30%" },
                            model: {
                              value: _vm.auditDetails.commission,
                              callback: function($$v) {
                                _vm.$set(_vm.auditDetails, "commission", $$v)
                              },
                              expression: "auditDetails.commission"
                            }
                          }),
                          _vm._v("\n            %\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "法人姓名:" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.auditDetails.legalPersonName) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "法人手机:" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.auditDetails.phone) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务范围:" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              size: "medium",
                              options: _vm.rootCity.children,
                              props: _vm.props,
                              itemscope: "",
                              "change-on-select": "false",
                              placeholder: "选择地区"
                            },
                            on: { change: _vm.cityChange },
                            model: {
                              value: _vm.auditDetails.areaId,
                              callback: function($$v) {
                                _vm.$set(_vm.auditDetails, "areaId", $$v)
                              },
                              expression: "auditDetails.areaId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "logo:" } },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.auditDetails.avatar,
                                fit: "fill",
                                "preview-src-list": [_vm.details.avatar]
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder"
                                },
                                [
                                  _vm._v("\n                加载中"),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("...")
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "营业执照:" } },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: _vm.auditDetails.businessLicense,
                                fit: "fill",
                                "preview-src-list": [
                                  _vm.details.businessLicense
                                ]
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder"
                                },
                                [
                                  _vm._v("\n                加载中"),
                                  _c("span", { staticClass: "dot" }, [
                                    _vm._v("...")
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "nav",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.okAudit(_vm.auditDetails.id)
                        }
                      }
                    },
                    [_vm._v("审核通过")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.noAudit(_vm.auditDetails.id)
                        }
                      }
                    },
                    [_vm._v("审核不通过")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }