var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c("div", { staticClass: "query-wrapper" }, [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "el-form",
                { staticClass: "demo-form-inline", attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "会员名称" },
                        model: {
                          value: _vm.searchForm.keyword,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "keyword", $$v)
                          },
                          expression: "searchForm.keyword"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.fetchData },
                          model: {
                            value: _vm.searchForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "status", $$v)
                            },
                            expression: "searchForm.status"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "正常", value: "1" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "封禁", value: "2" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "审核未通过", value: "3" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "待审核", value: "0" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "更改区域", value: "4" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "el-button",
                {
                  staticClass: "query-button",
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.fetchData()
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重置")])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bread-nav-wrapper" }, [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "el-button",
                {
                  staticClass: "query-button",
                  attrs: {
                    type: "primary",
                    plain: "",
                    size: "min",
                    icon: "el-icon-plus"
                  },
                  on: {
                    click: function($event) {
                      return _vm.addOne()
                    }
                  }
                },
                [_vm._v("\n          新增\n        ")]
              ),
              _vm._v(" "),
              _vm.setBonus()
                ? _c(
                    "el-button",
                    {
                      staticClass: "query-button",
                      attrs: {
                        type: "primary",
                        plain: "",
                        size: "min",
                        icon: "el-icon-edit"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setRe()
                        }
                      }
                    },
                    [_vm._v("\n          设置返利\n        ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-wrapper" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading"
                  }
                ],
                attrs: {
                  "cell-style": { padding: "0.5ch" },
                  data: _vm.list,
                  "row-style": "height:20px",
                  "element-loading-text": "Loading",
                  border: "",
                  fit: "",
                  "highlight-current-row": ""
                }
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", label: "会员名称" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.name) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "联系电话" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.phone) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "所在城市", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("parentCityName")(scope.row.areaId))
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "所在区域", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("cityName")(scope.row.areaId)) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "class-name": "status-col",
                    label: "状态",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("userStatus")(scope.row)) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "class-name": "status-col",
                    label: "积分",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.integration) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "created_at",
                    label: "操作",
                    width: "250"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.editOne(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          scope.row.status === 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.enableOne(scope.row.id)
                                    }
                                  }
                                },
                                [_vm._v("解禁")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.status === 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.auditShowing(scope.row.id)
                                    }
                                  }
                                },
                                [_vm._v("审核")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.status === 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.disableOne(scope.row.id)
                                    }
                                  }
                                },
                                [_vm._v("封禁")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.applicationChangeArea
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeArea(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("更改区域\n            ")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { title: "更改区域", visible: _vm.changeRegion },
            on: {
              "update:visible": function($event) {
                _vm.changeRegion = $event
              }
            }
          },
          [
            _c(
              "el-form",
              { attrs: { "label-position": "right" } },
              [
                _c("el-form-item", { attrs: { label: "公司名称:" } }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.details.name) + "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "申请更改服务区域为:", width: "11" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("applicationCityName")(_vm.details)) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nav",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.auditRegion(1)
                          }
                        }
                      },
                      [_vm._v("审核通过")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.auditRegion(2)
                          }
                        }
                      },
                      [_vm._v("审核不通过")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { title: "审核", visible: _vm.auditShow },
            on: {
              "update:visible": function($event) {
                _vm.auditShow = $event
              }
            }
          },
          [
            _c(
              "el-form",
              { attrs: { "label-position": "right" } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "公司名称:" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.auditDetails.name) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "服务范围:" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("fullCityName")(
                                        _vm.auditDetails.areaId
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "手机号:" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.auditDetails.phone) +
                              "\n            "
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "QQ号:" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.auditDetails.qq) +
                              "\n            "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "营业执照:" } },
                          [
                            _c(
                              "el-image",
                              {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: _vm.auditDetails.businessLicense,
                                  fit: "fill",
                                  "preview-src-list": [
                                    _vm.auditDetails.businessLicense
                                  ]
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "placeholder" },
                                    slot: "placeholder"
                                  },
                                  [
                                    _vm._v("\n                  加载中"),
                                    _c("span", { staticClass: "dot" }, [
                                      _vm._v("...")
                                    ])
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "nav",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.auditUser(true)
                          }
                        }
                      },
                      [_vm._v("审核通过")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.auditUser(false)
                          }
                        }
                      },
                      [_vm._v("审核不通过")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "等级提成设置",
              visible: _vm.dialogTableVisible,
              width: "30%"
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogTableVisible = $event
              }
            }
          },
          [
            _c(
              "el-form",
              { attrs: { "label-position": "right" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "选择等级:" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.setChange },
                        model: {
                          value: _vm.settingsValue.index,
                          callback: function($$v) {
                            _vm.$set(_vm.settingsValue, "index", $$v)
                          },
                          expression: "settingsValue.index"
                        }
                      },
                      _vm._l(_vm.levelSetting, function(item, index) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name + "级", value: index }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "设置返利:" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "29ch" },
                      attrs: { type: "text" },
                      model: {
                        value: _vm.settingsValue.value,
                        callback: function($$v) {
                          _vm.$set(_vm.settingsValue, "value", $$v)
                        },
                        expression: "settingsValue.value"
                      }
                    }),
                    _vm._v("\n          %\n        ")
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "nav",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.okRe }
                      },
                      [_vm._v("确定")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      { attrs: { size: "small" }, on: { click: _vm.cancel } },
                      [_vm._v("取消")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "nav",
          { staticClass: "pagination-app" },
          [
            _c("el-pagination", {
              attrs: {
                "page-size": _vm.searchForm.size,
                "page-sizes": [5, 10, 20, 50],
                layout: "total, prev, pager, next",
                total: _vm.total
              },
              on: { "current-change": _vm.handleCurrentChange }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }