var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 6 } }, [_vm._v(" ")]),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      ref: "form",
                      model: _vm.form,
                      "label-position": "left",
                      rules: _vm.rules,
                      "label-width": "100px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "公司名称", prop: "name" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.name,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "服务范围", prop: "name" } },
                      [
                        _c("el-cascader", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "medium",
                            options: _vm.rootCity.children,
                            props: _vm.props,
                            itemscope: "",
                            "change-on-select": "false",
                            placeholder: "选择地区"
                          },
                          on: { "active-item-change": _vm.handleItemChange },
                          model: {
                            value: _vm.citySelect,
                            callback: function($$v) {
                              _vm.citySelect = $$v
                            },
                            expression: "citySelect"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号", prop: "phone" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "登录帐号", prop: "account" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.account,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "account", $$v)
                            },
                            expression: "form.account"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.type !== "edit"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "登录密码", prop: "password" } },
                          [
                            _c("el-input", {
                              attrs: { type: "password" },
                              model: {
                                value: _vm.form.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "password", $$v)
                                },
                                expression: "form.password"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "等级", prop: "level" } },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.form.level,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "level", $$v)
                              },
                              expression: "form.level"
                            }
                          },
                          _vm._l(_vm.levelSetting, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.name + "级",
                                value: item.name
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "营业执照", prop: "businessLicense" } },
                      [
                        _c(
                          "el-upload",
                          {
                            attrs: {
                              "on-success": _vm.handleAvatarSuccess,
                              action: _vm.upload,
                              "file-list": _vm.fileList1,
                              "list-type": "picture-card",
                              "auto-upload": "true"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "file",
                                fn: function(ref) {
                                  var file = ref.file
                                  return _c("div", {}, [
                                    _c("img", {
                                      staticClass:
                                        "el-upload-list__item-thumbnail",
                                      attrs: { src: file.url, alt: "" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-upload-list__item-actions"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-upload-list__item-preview",
                                            on: {
                                              click: function($event) {
                                                return _vm.handlePictureCardPreview(
                                                  file
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-zoom-in"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !_vm.disabled
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "el-upload-list__item-delete",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleRemove(
                                                      file
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-delete"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                }
                              }
                            ])
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              attrs: { slot: "default" },
                              slot: "default"
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "办公场地", prop: "officePictures" } },
                      [
                        _c(
                          "el-upload",
                          {
                            attrs: {
                              "on-success": _vm.handlePic1Success,
                              action: _vm.upload,
                              "file-list": _vm.fileList,
                              "list-type": "picture-card",
                              "auto-upload": true
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "file",
                                fn: function(ref) {
                                  var file = ref.file
                                  return _c("div", {}, [
                                    _c("img", {
                                      staticClass:
                                        "el-upload-list__item-thumbnail",
                                      attrs: { src: file.url, alt: "" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-upload-list__item-actions"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-upload-list__item-preview",
                                            on: {
                                              click: function($event) {
                                                return _vm.handlePictureCardPreview(
                                                  file
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-zoom-in"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !_vm.disabled
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "el-upload-list__item-delete",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleRemove(
                                                      file
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-delete"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                }
                              }
                            ])
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              attrs: { slot: "default" },
                              slot: "default"
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dialog",
                          {
                            attrs: { visible: _vm.dialogVisible },
                            on: {
                              "update:visible": function($event) {
                                _vm.dialogVisible = $event
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                width: "100%",
                                src: _vm.dialogImageUrl,
                                alt: ""
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "15ch" },
                            attrs: { type: "primary", size: "medium" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("form")
                              }
                            }
                          },
                          [_vm._v("保存")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "15ch" },
                            attrs: { size: "medium" },
                            on: {
                              click: function($event) {
                                return _vm.$router.back()
                              }
                            }
                          },
                          [_vm._v("返回")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 6 } }, [_vm._v(" ")])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }