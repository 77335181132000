"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOne = exports.editOne = exports.getList = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var getList = function getList(params) {
  return (0, _request.default)({
    url: '/feedback',
    method: 'get',
    params: params
  });
};

exports.getList = getList;

var editOne = function editOne(data) {
  console.log(data);
  return (0, _request.default)({
    url: "/feedback/".concat(data.id),
    method: 'put',
    data: data
  });
};

exports.editOne = editOne;

var getOne = function getOne(id) {
  return (0, _request.default)({
    url: "/carousels/".concat(id),
    method: 'get'
  });
};

exports.getOne = getOne;