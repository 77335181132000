var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "query-wrapper" }, [
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-form",
              { staticClass: "demo-form-inline", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "订单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "订单号" },
                      model: {
                        value: _vm.searchForm.orderNo,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "orderNo", $$v)
                        },
                        expression: "searchForm.orderNo"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "会员名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "会员名称" },
                      model: {
                        value: _vm.searchForm.name,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "name", $$v)
                        },
                        expression: "searchForm.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "商家名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "商家名称" },
                      model: {
                        value: _vm.searchForm.merchantName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "merchantName", $$v)
                        },
                        expression: "searchForm.merchantName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.search },
                        model: {
                          value: _vm.searchForm.status,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "status", $$v)
                          },
                          expression: "searchForm.status"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "待支付", value: "1" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "待发货", value: "2" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "待收货", value: "3" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "已完成", value: "4" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "申请退款", value: "6" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "申请退款退货", value: "7" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "已取消", value: "5" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateChange },
                      model: {
                        value: _vm.dateRange,
                        callback: function($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "下单时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        align: "right",
                        "unlink-panels": "",
                        "value-format": "timestamp",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "picker-options": _vm.pickerOptions
                      },
                      on: { change: _vm.changeDate },
                      model: {
                        value: _vm.value2,
                        callback: function($$v) {
                          _vm.value2 = $$v
                        },
                        expression: "value2"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right", staticStyle: { "min-width": "240px" } },
          [
            _c(
              "el-button",
              {
                staticClass: "query-button",
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.search }
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重置")])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            "cell-style": { padding: "0.5ch" },
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "会员名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商家名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.merchantname) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单编号", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.orderNo) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "下单时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("dateTime")(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("orderStatus")(scope.row.status)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单金额", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.payPrices))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "更新时间",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("dateTime")(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "操作",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.viewOne(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "pagination-app" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": _vm.searchForm.size,
              layout: "total, prev, pager, next",
              total: _vm.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "订单详情", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("H4", [_vm._v("订单编号：" + _vm._s(_vm.details.orderNo))])
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 4 } }, [_vm._v("    ")]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _vm.details.payPrices
                    ? _c("H4", [
                        _vm._v(
                          "订单报价：" + _vm._s(_vm.details.payPrices + "元")
                        )
                      ])
                    : _c("H4", [_vm._v("订单报价:未报价")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("H2", [_vm._v("订单清单")]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.details.listing,
                "max-height": "600",
                label: "订单清单"
              }
            },
            [
              _c("el-table-column", {
                attrs: { property: "name", label: "物价名称" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "count", label: "数量" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "price", label: "单价" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "unit", label: "单位" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("H4", [
                    _vm._v(
                      "总计金额：" + _vm._s(_vm.details.amountPrices) + " 元"
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("H4", [
                    _vm._v("商家名称：" + _vm._s(_vm.details.merchant.name))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.details.address
                    ? _c("H4", [
                        _vm._v("收货人：" + _vm._s(_vm.details.address.name))
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.details.address
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("H4", [
                        _vm._v("邮政编码：" + _vm._s(_vm.details.address.zip))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("H4", [
                        _vm._v("联系方式：" + _vm._s(_vm.details.address.phone))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.details.address
            ? _c(
                "el-row",
                _vm._l(_vm.details.pictures, function(item) {
                  return _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          src: item,
                          "preview-src-list": _vm.details.pictures
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.details.company
                    ? _c("H4", [
                        _vm._v("快递公司：" + _vm._s(_vm.details.company))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.details.waybill
                    ? _c("H4", [
                        _vm._v("快递单号：" + _vm._s(_vm.details.waybill))
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _vm.details.address
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("H4", [
                        _vm._v(
                          "收货地址：" +
                            _vm._s(_vm.details.address.area) +
                            _vm._s(_vm.details.address.details)
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [_c("H4", [_vm._v("评分：")])],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 22 } },
                [
                  _c("el-rate", {
                    staticStyle: { margin: "18px" },
                    attrs: {
                      disabled: "",
                      max: 10,
                      "show-score": "",
                      "text-color": "#ff9900",
                      "score-template": "{value}"
                    },
                    model: {
                      value: _vm.details.score,
                      callback: function($$v) {
                        _vm.$set(_vm.details, "score", $$v)
                      },
                      expression: "details.score"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogTableVisible = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }