"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.china = china;
exports.chinaRoot = chinaRoot;

var _request = _interopRequireDefault(require("@/utils/request"));

function china() {
  return (0, _request.default)({
    url: process.env.VUE_APP_BASE + 'china',
    method: 'get'
  });
}

function chinaRoot() {
  return (0, _request.default)({
    url: process.env.VUE_APP_BASE + 'china/0',
    method: 'get'
  });
}