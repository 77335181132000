"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;
exports.validUsername = validUsername;
exports.validPhone = validPhone;
exports.validatePhone = void 0;

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validUsername(str) {
  var valid_map = [''];
  return valid_map.indexOf(str.trim()) < 0;
}
/**
 *
 * @param phone
 * @returns {boolean}
 */


function validPhone(phone) {
  return /^1[3456789]\d{9}$/.test(phone);
}
/**
 * 电话号码验证器
 * @param rule
 * @param value
 * @param callback
 */


var validatePhone = function validatePhone(rule, value, callback) {
  if (!validPhone(value)) {
    callback(new Error('请输入正确电话号码'));
  } else {
    callback();
  }
};

exports.validatePhone = validatePhone;