var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c("el-col", { attrs: { span: 6 } }, [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "主题名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "54%" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "54%" },
                    model: {
                      value: _vm.form.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "起止日期" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "54%" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    on: {
                      change: function($event) {
                        return _vm.daterangeChange()
                      }
                    },
                    model: {
                      value: _vm.daterange,
                      callback: function($$v) {
                        _vm.daterange = $$v
                      },
                      expression: "daterange"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "轮播图" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.upload,
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess,
                        "before-upload": _vm.beforeAvatarUpload
                      }
                    },
                    [
                      _vm.form.image
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.form.image }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                            staticStyle: {
                              border: "1px dashed #d9d9d9",
                              "border-radius": "6px",
                              cursor: "pointer",
                              position: "relative",
                              overflow: "hidden"
                            }
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "跳转连接" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "54%" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 4 },
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.form.url,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "medium" },
                      on: {
                        click: function($event) {
                          return _vm.subForm("form")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [_vm._v("返回")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-col", { attrs: { span: 6 } }, [_vm._v(" ")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }