var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "query-wrapper" }, [
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-form",
              { staticClass: "demo-form-inline", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "会员名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "会员名称" },
                      model: {
                        value: _vm.searchForm.name,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "name", $$v)
                        },
                        expression: "searchForm.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "商家名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "商家名称" },
                      model: {
                        value: _vm.searchForm.merchantName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "merchantName", $$v)
                        },
                        expression: "searchForm.merchantName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "交易时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "picker-options": _vm.pickerOptions,
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateChange },
                      model: {
                        value: _vm.dateRange,
                        callback: function($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-button",
              {
                staticClass: "query-button",
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.search }
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重置")])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            "cell-style": { padding: "0.5ch" },
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "订单编号", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.orderNo) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "会员名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.memberName) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商家名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.merchantName) + "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "交易积分", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.amount))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "平台抽成", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.commission))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商家所得", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.merchantEarnings) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "返利", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.rebate))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "平台盈利", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.profit))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "完成时间",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("dateTime")(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { staticStyle: { "margin-top": "2ch" }, attrs: { inline: "true" } },
        [
          _c("el-form-item", { attrs: { label: "完成交易数:" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("nullfilter")(_vm.statistical.total)) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "交易积分数:" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("nullfilter")(_vm.statistical.amount)) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "返利积分数:" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("nullfilter")(_vm.statistical.rebate)) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "盈利积分数:" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("nullfilter")(_vm.statistical.profit)) +
                "\n    "
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "pagination-app" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": _vm.searchForm.size,
              layout: "total, prev, pager, next",
              total: _vm.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }