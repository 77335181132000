"use strict";

var _interopRequireDefault = require("/nas/jenkins_ci/workspace/Node-GuangLianHui2-Bam/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.payWayMap = exports.parentCityName = exports.messageType = exports.nullfilter = exports.withdrawlStatus = exports.applicationCityName = exports.commission = exports.userStatus = exports.cityName = exports.carouselStatus = exports.feedStatus = exports.merchantStatus = exports.fullCityName = exports.dateTime = exports.orderStatus = exports.date = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _store = _interopRequireDefault(require("@/store"));

var _this = _vue.default.prototype;

var cityName = function cityName(value) {
  var china = _store.default.getters.china;

  for (var i = 0, len = china.length; i < len; i++) {
    if (china[i].Id === value) {
      return china[i].Name;
    }
  }
};

exports.cityName = cityName;

var fullCityName = function fullCityName(value) {
  if (value === undefined) {
    return '';
  }

  var temp = filterCity(value);
  return pCity(temp);
};

exports.fullCityName = fullCityName;

var applicationCityName = function applicationCityName(value) {
  if (value === undefined) {
    return '';
  }

  console.log(value);

  if (value.applicationChangeArea !== null) {
    var temp = filterCity(value.applicationChangeArea.newArea);
    if (temp) return pCity(temp);else return '-';
  } else {
    return '-';
  }
};

exports.applicationCityName = applicationCityName;

var filterCity = function filterCity(value) {
  var china = _store.default.getters.china;

  for (var i = 0, len = china.length; i < len; i++) {
    if (china[i].Id === value) {
      return china[i];
    }
  }
};

var pCity = function pCity(value) {
  var name = value.Name;

  if (value.Pid !== null) {
    var pcity = filterCity(value.Pid);
    if (pcity.Id !== 0) name = pCity(pcity) + name;
  }

  return name;
};

var parentCityName = function parentCityName(value) {
  if (value === undefined) {
    return '';
  }

  var temp = filterCity(value);
  return pCity2(temp);
};

exports.parentCityName = parentCityName;

var pCity2 = function pCity2(value) {
  var name = '';
  if (value === undefined) return '';

  if (value.Pid !== null) {
    var pcity = filterCity(value.Pid);
    if (pcity.Id !== 0) name = pCity(pcity) + name;
  }

  return name;
};

var orderStatus = function orderStatus(value) {
  var map = {
    0: '待整理',
    1: '待支付',
    2: '待发货',
    3: '待收货',
    4: '完成',
    5: '已取消',
    6: '申请退款',
    7: '申请退款&退货',
    8: '已寄回',
    9: '已退款'
  };
  return map[value];
};

exports.orderStatus = orderStatus;

var carouselStatus = function carouselStatus(value) {
  var map = {
    0: '停用',
    1: '正常'
  };
  return map[value];
};

exports.carouselStatus = carouselStatus;

var feedStatus = function feedStatus(value) {
  var map = {
    0: '末处理',
    1: '已处理'
  };
  return map[value];
};

exports.feedStatus = feedStatus;

var nullfilter = function nullfilter(value) {
  if (value) return value;else return '-';
};

exports.nullfilter = nullfilter;

var withdrawlStatus = function withdrawlStatus(value) {
  var map = {
    0: '待审核',
    1: '审核完成(通过)',
    2: '审核完成(末通过)'
  };
  return map[value];
};

exports.withdrawlStatus = withdrawlStatus;

var merchantStatus = function merchantStatus(value) {
  var map = {
    0: '待审核',
    1: '正常',
    2: '禁封',
    3: '审核未通过',
    4: '更改域'
  };

  if (value.status === 1) {
    if (value.applicationChangeArea === null) return map[value.status];else return map[4];
  } else return map[value.status];
};

exports.merchantStatus = merchantStatus;

var date = function date(e) {
  if (e && e !== 'null' && e !== null) {
    return _this.$moment(e).format('MM/DD/YYYY');
  } else {
    return '';
  }
};

exports.date = date;

var dateTime = function dateTime(e) {
  if (e && e !== 'null' && e !== null) {
    return _this.$moment(e).format('YYYY-MM-DD HH:mm');
  } else {
    return '';
  }
};

exports.dateTime = dateTime;

var commission = function commission(e) {
  if (e && e !== 'null' && e !== null) {
    return e + '%';
  } else {
    return '－';
  }
};

exports.commission = commission;

var userStatus = function userStatus(value) {
  var map = {
    0: '待审核',
    1: '正常',
    2: '禁封',
    3: '审核未通过'
  };

  if (value.status === 1) {
    if (value.applicationChangeArea === null) return map[value.status];else return '更改区域';
  }

  return map[value.status];
};

exports.userStatus = userStatus;

var messageType = function messageType(value) {
  var map = {
    0: '全部',
    1: '商户',
    2: '用户'
  };
  return map[value];
};

exports.messageType = messageType;

var payWayMap = function payWayMap(value) {
  var map = {
    1: '微信',
    2: '支付宝',
    3: '返利'
  };
  return map[value];
};

exports.payWayMap = payWayMap;